


































import Vue from "vue";

export default Vue.extend<any, any, any, any>({
  name: "ListGroup",
  components: {
    ListItem: () => import("./ListItem.vue"),
  },
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    gradient: 0,
  }),
  methods: {
    genGroup(items: []): string {
      return items
        .reduce((acc: string[], cur: { to: string; items: [] }) => {
          if (!cur.to) return acc;

          const d = cur.items ? this.genGroup(cur.items) : cur.to.slice(1, -1);

          acc.push(d);

          return acc;
        }, [])
        .join("|");
    },
  },
  computed: {
    group(): string {
      return this.genGroup(this.item.items);
    },
    title() {
      const matches = this.item.title.match(/\b(\w)/g);

      return matches.join("");
    },
  },
});
